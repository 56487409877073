import React from "react";
import "../css/media.css";
import underline from "../images/underline.png";
import web_dev_e1652943655726 from "../images/web-dev-e1652943655726.png";
import Group_43 from "../images/Group-43.png";
import Group_44 from "../images/Group-44.png";
import Group_45 from "../images/Group-45.png";
import Group_46 from "../images/Group-46.png";
import Group_47 from "../images/Group-47.png";
import Group_48 from "../images/Group-48.png";
import Website_Development from "../images/Website-Development.jpg";
import Group_43_2 from "../images/Group-43-2.png";
import Group_44_2 from "../images/Group-44-2.png";
import Group_45_1 from "../images/Group-45-1.png";
import Group_47_1 from "../images/Group-47-1.png";
import Group_46_1 from "../images/Group-46-1.png";
import Group_48_1 from "../images/Group-48-1.png";
import Group_49_1 from "../images/Group-49-1.png";
import Group_50_1 from "../images/Group-50-1.png";
import Group_51_1 from "../images/Group-51-1.png";
import Group_52_1 from "../images/Group-52-1.png";
import Group_53_1 from "../images/Group-53-1.png";
import Group_54_1 from "../images/Group-54-1.png";
import OwlCarousel from "react-owl-carousel";
import quote1 from "../images/Double-quote1.png";
import quote2 from "../images/Double-quote2.png";
import quote3 from "../images/Double-quote3.png";
import quote4 from "../images/Double-quote4.png";
import vector1 from "../images/Vector1.png";
import vector2 from "../images/Vector-2.png";
import vector3 from "../images/Vector3.png";
import vector4 from "../images/Vector4.png";
import rico from "../images/rico.jpg";
import alex from "../images/alex.jpg";
import vaneet from "../images/vaneet.jpg";
import nick from "../images/nick.jpg";
import roman from "../images/roman.jpg";
import cedric from "../images/cedric.jpg";
import tom from "../images/tom.jpeg";
import Touch from "./Touch";
import Footer from "./Footer";

const Web_development = () => {
  return (
    <div>
      <section className="We-Build-all">
        <div className="We-Build">
          <div className="container">
            <div className="row">
              <div className="col-6 we-build_text px-5">
                <div className="we-data">
                  <div className="top-data">
                    <h2 className="software">WEB APPLICATION DEVELOPMENT</h2>
                  </div>
                  <div className="bottom-data">
                    <p>
                      Your custom business ideas demand a highly responsive web
                      experience. Kalathiya Infotech’s bold approach assists
                      clients analyze disruptive B2B or B2C challenges to create
                      intuitive UI/UX, fully functional, and robust web
                      technology solutions.
                    </p>
                    <p className="mt-3">
                    Whether it's building intuitive user interfaces, implementing complex backend functionality, or ensuring seamless performance across devices, we're dedicated to delivering high-quality web applications that drive success and exceed expectations. Partner with us to transform your vision into reality and elevate your online presence.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 we-build_img">
                <img
                  src={web_dev_e1652943655726}
                  alt="web_dev_e1652943655726"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="web-application-development-services">
        <div className="container">
          <div className="web-application-services-data">
            <div className="web-application-services-data-top">
              <h2>WEB APPLICATION DEVELOPMENT SERVICES</h2>
              <img className="underline" src={underline} alt="underline" />
              <p>
                Kalathiya Infotech LLP is a renowned website development service
                company catering to various industries with our bespoke website
                development services.
              </p>
            </div>
            <div className="web-application-services-data-bottom">
              <div className="row web-application-card-row">
                <div className="col-4 web-application-card-col-4">
                  <div className="custom-application-border-card">
                    <div className="row web-application-row-img">
                      <div className="col-3 web-application-col-6-img">
                        <img src={Group_43} alt="Group_43" />
                      </div>
                      <div className="col-9 web-application-col-6-data-h3">
                        <h3>Custom Web Development</h3>
                      </div>
                    </div>
                    <p className="web-application-col-6-p">
                      We offer customized and quality website development
                      services for your business. Hire the top custom web
                      development company cost-effectively.
                    </p>
                  </div>
                </div>
                <div className="col-4 web-application-card-col-4">
                  <div className="portal-application-border-card">
                    <div className="row web-application-row-img">
                      <div className="col-3 web-application-col-6-img">
                        <img src={Group_44} alt="Group_43" />
                      </div>
                      <div className="col-9 web-application-col-6-data-h3">
                        <h3>Web Portal Development</h3>
                      </div>
                    </div>
                    <p className="web-application-col-6-p">
                      Our wide range of web portal development services helps
                      you get the complete chain of business verticals using
                      versatile development experience.
                    </p>
                  </div>
                </div>
                <div className="col-4 web-application-card-col-4">
                  <div className="commerce-application-border-card">
                    <div className="row web-application-row-img">
                      <div className="col-3 web-application-col-6-img">
                        <img src={Group_45} alt="Group_43" />
                      </div>
                      <div className="col-9 web-application-col-6-data-h3">
                        <h3>E-commerce Development</h3>
                      </div>
                    </div>
                    <p className="web-application-col-6-p">
                      We will provide intellectual resolution for your business
                      as we have delivered a complete business project of the
                      E-commerce platform for B2B and B2C businesses.
                    </p>
                  </div>
                </div>
                <div className="col-4 web-application-card-col-4">
                  <div className="integration-application-border-card">
                    <div className="row web-application-row-img">
                      <div className="col-3 web-application-col-6-img">
                        <img src={Group_46} alt="Group_46" />
                      </div>
                      <div className="col-9 web-application-col-6-data-h3">
                        <h3>Web Open Source Integration and Customization</h3>
                      </div>
                    </div>
                    <p className="web-application-col-6-p">
                      We offer open-source web development services with the
                      help of our skilled and experienced resources for
                      open-source technologies to maximize business outcomes.
                    </p>
                  </div>
                </div>
                <div className="col-4 web-application-card-col-4">
                  <div className="mobile-application-border-card">
                    <div className="row web-application-row-img">
                      <div className="col-3 web-application-col-6-img">
                        <img src={Group_47} alt="Group_47" />
                      </div>
                      <div className="col-9 web-application-col-6-data-h3">
                        <h3>Mobile-Friendly Website Development</h3>
                      </div>
                    </div>
                    <p className="web-application-col-6-p">
                      Our team develops a compelling mobile presence solution
                      that is easily accessible on all handsets or devices. web
                      applications from our experienced resources.
                    </p>
                  </div>
                </div>
                <div className="col-4 web-application-card-col-4">
                  <div className="flexible-application-border-card">
                    <div className="row web-application-row-img">
                      <div className="col-3 web-application-col-6-img">
                        <img src={Group_48} alt="Group_48" />
                      </div>
                      <div className="col-9 web-application-col-6-data-h3">
                        <h3>Flexible Web Maintenance Custom Web Development</h3>
                      </div>
                    </div>
                    <p className="web-application-col-6-p">
                      Find our post-development support and accessible web
                      maintenance services to keep your website updated and
                      function hassle-free.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features-of-Website-development ">
        <div className="container">
          <div className="row features-of-Website-row">
            <div className="col-6 features-of-Website-col-6">
              <div className="features-of-Website-data">
                <div className="features-of-h3">
                  <h3 className="features-top-h3">Features of</h3>
                  <h3 className="features-bottom-h3">Website Development</h3>
                </div>
                <div className="features-of-p">
                  <p>
                    By keeping your budget in mind, our expert developers assist
                    you in building an excellent website and maintaining it
                    regularly.
                  </p>
                </div>
                <div className="features-of-ul">
                  <ul>
                    <li>Proper understanding of client’s requirement</li>
                    <li>User – Friendly content</li>
                    <li>Adequate structure of a website</li>
                    <li>Friendly admin page for marketing purposes</li>
                    <li>A checkout page</li>
                    <li>Security</li>
                    <li>Required plugins for backend purpose</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6 features-image">
              <div className="features-of-Website-img">
                <img src={Website_Development} alt="Website_Development" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="get-a-free-mobile">
        <div className="get-a-free-mobile-all">
          <div className="container">
            <div className="row get-a-free-row">
              <div className="col-9 get-a-free-col-9">
                <div className="get-a-free-data">
                  <h3>Get a Free Mobile App Development consultation</h3>
                  <h2>with our experts today!</h2>
                </div>
              </div>
              <div className="col-3 get-a-free-col-3">
                <div className="get-a-free-button">
                  <a href="/contact">
                  <button className="btn btn-warning">CONTACT US</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-expertise">
        <div className="container">
          <div className="our-expertise-data-head">
            <div className="our-expertise-data-top-head">
              <h2>OUR CORE SERVICES</h2>
              <img className="underline" src={underline} alt="underline" />
            </div>
            <div className="our-core-data-bottom">
              <div className="row our-expertise-data-row">
                <div className="col-2 our-expertise-data-col-2">
                  <div className="education-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_43_2} alt="Group_43_2" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Education</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="health-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_44_2} alt="Group_44_2" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Health Care</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="commerce-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_45_1} alt="Group_45_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>E-commerce</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="fitness-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_47_1} alt="Group_47_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Fitness</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="lifestyle-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_46_1} alt="Group_46_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Lifestyle</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="transportation-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_48_1} alt="Group_48_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Transportation</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="fitness-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_49_1} alt="Group_49_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Real Estate</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="event-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_50_1} alt="Group_50_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Event Management</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="lifestyle-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_51_1} alt="Group_51_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Travel</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="education-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_52_1} alt="Group_52_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Finance</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="health-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_53_1} alt="Group_53_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Social Network</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="commerce-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_54_1} alt="Group_54_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Food & Drink</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Touch />
      <Footer />
    </div>
  );
};

export default Web_development;
