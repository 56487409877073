import React, { useState } from "react";
import "../css/media.css";
import ReCAPTCHA from "react-google-recaptcha";
import { faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Touch = () => {
  const [ name, setname ] = useState("");
  const [ email, setemail ] = useState("");
  const [ subject, setsubject ] = useState("");
  const [ message, setyourmsg ] = useState("");
  const [ nameError, setNameError ] = useState(false);
  const [ emailError, setEmailError ] = useState(false);
  const [ subjectError, setSubjectError ] = useState(false);
  const [ messageError, setMessageError ] = useState(false);

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  async function handlesubit() {
    let isValid = true;

    // Check name
    if (!name) {
      setNameError("Please enter your name");
      isValid = false;
    } else {
      setNameError("");
    }

    // Check email
    if (!email) {
      setEmailError("Please enter your email");
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Check subject
    if (!subject) {
      setSubjectError("Please enter a subject");
      isValid = false;
    } else {
      setSubjectError("");
    }

    // Check message
    if (!message) {
      setMessageError("Please enter your message");
      isValid = false;
    } else {
      setMessageError("");
    }

    if (isValid) {
      await axios
        .post("http://localhost:5000/message", {
          name: name,
          email: email,
          subject: subject,
          message: message
        })
        .then(function(response) {
          console.log(response);
          setname("");
          setemail("");
          setsubject("");
          setyourmsg("");
          toast.success("Thank you for send message!");
        })
        .catch(function(error) {
          console.log(error);
          toast.error("Error sending message.");
        });
    }
  }
  return (
    <div>
      <section className="contact-touch">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="contact-touch-data">
                <h3>We are here to help!</h3>
              </div>
              <div className="contact-icon">
                <div className="icon-data">
                  <h5>Surat</h5>
                  <div className="contact-address">
                    <FontAwesomeIcon className="i" icon={faMapMarkerAlt} />
                    <a
                      href="https://www.google.com/maps/place/Kalathiya+Infotech/@21.2057926,72.8818075,20.82z/data=!4m9!1m2!2m1!1s102-103+Silver+Point,+Jivanjyot+Circle,+Nr+Yogi+chowk+Road+Surat+-395010!3m5!1s0x26cb5e4230fc8877:0xd36ccfe485cd6a01!8m2!3d21.205932!4d72.8818071!16s%2Fg%2F11kbyh9bk6?entry=ttu"
                      target="_blank"
                    >
                      102-103 Silver Point, Jivanjyot Circle, Nr Yogi chowk Road
                      Surat -395010 (Head Office)
                    </a>
                  </div>
                  <div className="contact-address">
                    <FontAwesomeIcon className="i" icon={faMapMarkerAlt} />
                    <a
                      href="#"
                      target="_blank"
                    >
                       506, Sky Point, City Light Near Science Center Road,
                       Surat -395003(Branch)
                    </a>
                  </div>
                  <div className="contact-address">
                    <FontAwesomeIcon className="i" icon={faPhone} />
                    <a href="tel:(0261)3102715">+0261 3102715</a>
                  </div>
                </div>
                <div className="icon-data">
                  <h5>Canada</h5>
                  <div className="contact-address">
                    <FontAwesomeIcon className="i" icon={faMapMarkerAlt} />
                    <a
                      href="https://www.google.com/maps/place/855+King+St+E+%23210,+Cambridge,+ON+N3H+3P2,+Canada/@43.3949383,-80.3584763,17z/data=!3m1!4b1!4m5!3m4!1s0x882b89f0377066e3:0x13fe980777df8121!8m2!3d43.3949383!4d-80.3559014?entry=ttu"
                      target="_blank"
                    >
                      855 King Street East,Unit 210 Cambridge, ON, Canada
                      (Branch)
                    </a>
                  </div>
                  <div className="contact-address">
                    <FontAwesomeIcon className="i" icon={faPhone} />
                    <a href="tel:+15199817124">+1 5199817124</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="border">
                <div>
                  <div className="contact-touch-head">
                    <h3>Let's Get In Touch</h3>
                  </div>
                  <div className="contact-touch-body">
                    <div className="mb-3 d-flex">
                      <input
                        type="text"
                        className={`form-control name ${nameError
                          ? "is-invalid"
                          : ""}`}
                        id="exampleFormControlInput1"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                        required
                      />

                      <input
                        type="email"
                        className={`form-control email ${emailError
                          ? "is-invalid"
                          : ""}`}
                        id="exampleFormControlInput1"
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="d-flex">
                      {nameError && (
                        <span className="invalid-feedback mt-0 mb-2 d-block">
                          {nameError}
                        </span>
                      )}
                      {emailError && (
                        <div className="invalid-feedback mt-0 mb-2 d-block">
                          {emailError}
                        </div>
                      )}
                    </div>

                    <div className="mb-3">
                      <input
                        type="text"
                        className={`form-control email ${subjectError
                          ? "is-invalid"
                          : ""}`}
                        id="exampleFormControlInput1"
                        placeholder="Subject"
                        value={subject}
                        onChange={(e) => setsubject(e.target.value)}
                        required
                      />
                      {subjectError && (
                        <div className="invalid-feedback">{subjectError}</div>
                      )}
                    </div>
                    <div className="mb-3">
                      <textarea
                        className={`form-control ${messageError
                          ? "is-invalid"
                          : ""}`}
                        id="exampleFormControlTextarea1"
                        placeholder="Your Message"
                        rows={3}
                        defaultValue={""}
                        value={message}
                        onChange={(e) => setyourmsg(e.target.value)}
                        required
                      />
                      {messageError && (
                        <div className="invalid-feedback">{messageError}</div>
                      )}
                    </div>
                    <div className="button mb-3 d-flex">
                      <div
                        className="margin catpa"
                        style={{ marginRight: "auto" }}
                      >
                        <ReCAPTCHA
                          sitekey={"6LeU7rMpAAAAAFlcq0q5YzBo-HdadiPaA7UOU3K7"}
                        />
                      </div>
                      <button className="btn-contact" onClick={handlesubit}>
                        SEND MESSAGE
                      </button>
                      <Toaster />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Touch;
