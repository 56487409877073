import React from "react";
import design from "../images/Design (1).png";
import underline from "../images/underline.png";
import designservice1 from "../images/design-service1.png";
import designservice2 from "../images/design-service2.png";
import designservice3 from "../images/design-service3.png";
import designservice4 from "../images/design-service4.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import quote1 from "../images/Double-quote1.png";
import quote2 from "../images/Double-quote2.png";
import quote3 from "../images/Double-quote3.png";
import quote4 from "../images/Double-quote4.png";
import vector1 from "../images/Vector1.png";
import vector2 from "../images/Vector-2.png";
import vector3 from "../images/Vector3.png";
import vector4 from "../images/Vector4.png";
import rico from "../images/rico.jpg";
import alex from "../images/alex.jpg";
import vaneet from "../images/vaneet.jpg";
import nick from "../images/nick.jpg";
import roman from "../images/roman.jpg";
import cedric from "../images/cedric.jpg";
import tom from "../images/tom.jpeg";
import "../css/media.css";
import Footer from "./Footer";
import Touch from "./Touch";

const Uiux = () => {
  return (
    <div>
      <section className="design">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="design-img">
                <img src={design} alt="design" />
              </div>
            </div>
            <div className="col-6">
              <div className="design-first">
                <div className="design-heading">
                  <h2>DESIGN SERVICES</h2>
                </div>
                <div className="design-data">
                  <p>
                    Design is POWER; it inspires and convinces users to stay
                    engaged. We bring power to our design with a human-centered
                    design process by not just keeping the users in mind but
                    even by involving the users in the process. As a result, we
                    build A design that delights users.
                  </p>
                  <p className="mt-3">
                  Our design services encompass a wide spectrum, from captivating visual identities to intuitive user interfaces. With a keen eye for detail and a passion for creativity, our team of designers works closely with clients to understand their unique brand identity and objectives. Whether it's crafting stunning logos, designing engaging websites, or conceptualizing memorable marketing materials, we combine strategic thinking with innovative design to deliver impactful solutions that elevate your brand and leave a lasting impression.
                  </p>
                </div>
                {/* <div className='design-button'>
                                    <button className='hire-button'>HIRE NOW</button>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="design-service">
        <div className="container">
          <div className="design-service-head">
            <h2>OUR UX/UI DESIGN SERVICES</h2>
          </div>
          <div className="design-service-head-img">
            <img className="underline" src={underline} alt="underline" />
          </div>
          <div className="row">
            <div className="col-6">
              <div className="design-service-data">
                <div className="design-service-body">
                  <h2>Responsive Web Design Development</h2>
                  <p>
                    With latest design standards, we make the design accessible
                    on all platforms.
                  </p>
                </div>
                <div className="design-service-body-img">
                  <img src={designservice1} alt="designservice1" />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="design-service-data">
                <div className="design-service-body">
                  <h2>Mobile App Design Development</h2>
                  <p>
                    We make your native and cross-platform apps worth drooling
                    for.on all platforms.
                  </p>
                </div>
                <div className="design-service-body-img">
                  <img src={designservice2} alt="designservice2" />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="design-service-data">
                <div className="design-service-body">
                  <h2>Design Prototyping Development</h2>
                  <p>
                    With our prototyping services, we ensure your design works
                    for people and process.on all platforms.
                  </p>
                </div>
                <div className="design-service-body-img">
                  <img src={designservice3} alt="designservice3" />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="design-service-data">
                <div className="design-service-body">
                  <h2>PSD to HTML Development</h2>
                  <p>
                    Let’s turn your beautiful design layout in PSD into
                    responsive web pages with HTML.
                  </p>
                </div>
                <div className="design-service-body-img">
                  <img src={designservice4} alt="designservice4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="core-service mb-4">
        <div className="container">
          <div className="core-service-head">
            <h2>OUR CORE SERVICES</h2>
          </div>
          <div className="core-service-head-img">
            <img className="underline" src={underline} alt="underline" />
          </div>
          <div className="core-service-body">
            <div className="row core-service-row">
              <div className="col-4 core-service-col-4">
                <div className="core-head1">
                  <div className="row core-h3">
                    <div className="col-2 core-data-h3-1">
                      <h3>01</h3>
                    </div>
                    <div className="col-10 core-data-h2">
                      <h2>Research and Discovery</h2>
                    </div>
                  </div>
                  <div className="core-body">
                    <p>
                      With different research methods up our sleeves, we learn
                      about the users you are inviting. We put everything
                      together from likes to dislikes – and use this insight to
                      translate into a drooling design and flexible user
                      experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 core-service-col-4">
                <div className="core-head2">
                  <div className="row core-h3">
                    <div className="col-2 core-data-h3-2">
                      <h3>02</h3>
                    </div>
                    <div className="col-10 core-data-h2">
                      <h2>UI Design</h2>
                    </div>
                  </div>
                  <div className="core-body">
                    <p>
                      Bringing together left and right hemispheres of our brains
                      into play, we deliver visually rich design interfaces and
                      user-centric experiences that are grounded with research,
                      loved by users and developed with best technology.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 core-service-col-4">
                <div className="core-head3">
                  <div className="row core-h3">
                    <div className="col-2 core-data-h3-3">
                      <h3>03</h3>
                    </div>
                    <div className="col-10 core-data-h2">
                      <h2>UX Design</h2>
                    </div>
                  </div>
                  <div className="core-body">
                    <p>
                      The more seamless and glitch-free a user’s journey is, the
                      chances of meeting the targets are higher. Considering
                      that, we pay attention to designing an experience that can
                      talk to, listen to users. We help you with UX design
                      advantage you are looking for.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 core-service-col-4">
                <div className="core-head4">
                  <div className="row core-h3">
                    <div className="col-2 core-data-h3-4">
                      <h3>04</h3>
                    </div>
                    <div className="col-10 core-data-h2">
                      <h2>Information Architecture</h2>
                    </div>
                  </div>
                  <div className="core-body">
                    <p>
                      Usability plays a crucial role in design. With information
                      architecture skills we apply the art and logic to create
                      best structure and organize the site content and features
                      to enable flexible site navigation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 core-service-col-4">
                <div className="core-head5">
                  <div className="row core-h3">
                    <div className="col-2 core-data-h3-5">
                      <h3>05</h3>
                    </div>
                    <div className="col-10 core-data-h2">
                      <h2>MVP Development</h2>
                    </div>
                  </div>
                  <div className="core-body">
                    <p>
                      We help our clients by shaping their ideas into visuals
                      with the use of story mapping and affinity diagrams. This
                      ultimately helps turning the storyline into articulate
                      code of a solution.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 core-service-col-4">
                <div className="core-head6">
                  <div className="row core-h3">
                    <div className="col-2 core-data-h3-6">
                      <h3>06</h3>
                    </div>
                    <div className="col-10 core-data-h2">
                      <h2>Usability Testing</h2>
                    </div>
                  </div>
                  <div className="core-body">
                    <p>
                      With our usability skills we ensure your application or
                      website works flawlessly in the real business environment.
                      We evaluate the user experience of product at each stage,
                      identifying what issues are overlooked by UX developers
                      and product managers.rning the storyline into articulate
                      code of a solution.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="container">
        <div className="owl-head">
          <h3>OUR TESTIMONIAL</h3>
          <img className="underline" src={underline} alt="underline" />
        </div>

        <OwlCarousel
          className="owl-theme"
          responsive={{
            0: { items: 1, nav: false },
            768: { items: 2, nav: false },
            990: { nav: true },
          }}
          loop
          margin={10}
          nav
        >
          <div className="card card-1">
            <div className="quote">
              <img src={quote1} alt="quote1" />
            </div>
            <div class="item">
              <h4>
                What an awesome appgenix team, understood requirements easily,
                was very responsive, and maintained a professional attitude
                throughout. Done more than initially expected, and the work
                turned out great. Highly recommended and I will work with you
                again.
              </h4>
            </div>
            <div className="vector1">
              <img src={vector1} alt="vector1" />
            </div>
            <div className="user">
              <img src={rico} alt="rico" />
              <h3>Rico</h3>
            </div>
          </div>

          <div className="card card-2">
            <div className="quote">
              <img src={quote2} alt="quote2" />
            </div>
            <div class="item">
              <h4>
                Appgenix team was able to complete the project on time and at
                budget. He's ability to converse and understand the requirements
                is also way above average and I would have no hesitation in
                using Appgenix team in a future project. Thanks Appgenix team
                for your assistance even with changes during the project and the
                extended testing period you provided exemplary assistance.
              </h4>
            </div>
            <div className="vector2">
              <img src={vector2} alt="vector2" />
            </div>
            <div className="user">
              <img src={alex} alt="alex" />
              <h3>Alex</h3>
            </div>
          </div>

          <div className="card card-3">
            <div className="quote">
              <img src={quote3} alt="quote3" />
            </div>
            <div class="item">
              <h4>
                Excellent Team. Tricky application for driver Courier Delivery.
                22+ API integrated, robust code. Was quick in understanding the
                functionality, patience enabling us to modify mid way the
                functionality. Required some really tough features, all
                delivered. Highly recommended.
              </h4>
            </div>
            <div className="vector3">
              <img src={vector3} alt="vector3" />
            </div>
            <div className="user">
              <img src={vaneet} alt="vaneet" />
              <h3>Vaneet</h3>
            </div>
          </div>
          <div className="card card-4">
            <div className="quote">
              <img src={quote4} alt="quote4" />
            </div>
            <div class="item">
              <h4>
                Appgenix team did amazing work! The job was converting a pretty
                complex Figma file to React. They really went above and beyond
                to make it work. The React file that he sent us were pixel
                perfect the first time. There were no things had to be changed.
                They also did it very quickly too. Will definitely be working
                with Appgenix team again very shortly. I would highly recommend
                him to anyone.
              </h4>
            </div>
            <div className="vector4">
              <img src={vector4} alt="vector4" />
            </div>
            <div className="user">
              <img src={nick} alt="nick" />
              <h3>Nick</h3>
            </div>
          </div>

          <div className="card card-1">
            <div className="quote">
              <img src={quote1} alt="quote1" />
            </div>
            <div class="item">
              <h4>
                Appgenix team did an excellent job! They quickly understood the
                tasks and worked very hard to meet all requirements within time
                and budget. They are very capable full stack developer, highly
                recommended!
              </h4>
            </div>
            <div className="vector1">
              <img src={vector1} alt="vector1" />
            </div>
            <div className="user">
              <img src={roman} alt="roman" />
              <h3>Roman</h3>
            </div>
          </div>
          <div className="card card-2">
            <div className="quote">
              <img src={quote2} alt="quote2" />
            </div>
            <div class="item">
              <h4>
                An absolute pleasure to work with this professional team, very
                easy to communicate with and very very knowlegable, look forward
                to working with you again soon.
              </h4>
            </div>
            <div className="vector2">
              <img src={vector2} alt="vector2" />
            </div>
            <div className="user">
              <img src={cedric} alt="cedric" />
              <h3>Cedric</h3>
            </div>
          </div>
          <div className="card card-3">
            <div className="quote">
              <img src={quote3} alt="quote3" />
            </div>
            <div class="item">
              <h4>
                Absolutely incredible service. Delivered early and work was
                perfect. Was very considerate of changes made to the spec. Can't
                wait to work with Appgenix team again!
              </h4>
            </div>
            <div className="vector3">
              <img src={vector3} alt="vector3" />
            </div>
            <div className="user">
              <img src={tom} alt="tom" />
              <h3>Tom</h3>
            </div>
          </div>
        </OwlCarousel>
      </section> */}

      <Touch />

      <Footer />
    </div>
  );
};

export default Uiux;
