import React from "react";
import contact from "../images/contact.png";
import underline from "../images/underline.png";
import boss_1 from "../images/boss-1.png";
import boss_2 from "../images/boss-2.png";
import "../css/media.css";
import Footer from "./Footer";
import Touch from "./Touch";

const Contact = () => {
  
  return (
    <div>
      <section className="contact">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="contact-last">
                <div className="contact-heading">
                  <h2>CONTACT US</h2>
                </div>
                <div className="contact-span">
                  <span>Do you have any Queries? We’re happy to help!</span>
                </div>
                <div className="contact-data">
                  <p >
                  Welcome to Kalathiya infotech where innovation meets expertise. With a passion for technology and a commitment to excellence, we specialize in providing cutting-edge IT solutions tailored to meet the unique needs of our clients. Our team of skilled professionals brings together a wealth of experience and knowledge to deliver top-notch services in software development, cybersecurity, cloud computing, and beyond.  We strive for nothing less than perfection, working tirelessly to ensure our clients stay ahead in an ever-evolving digital landscape. Discover the difference with KALATHIYA INFOTECH today.
                  </p>
                  <p className="mt-3">
                    At KALATHIYA INFOTECH, we are dedicated to providing
                    exceptional service and support to our clients. Whether you
                    have a question about our products, need technical
                    assistance, or want to explore partnership opportunities,
                    we're here to help. Our experienced team is ready to assist
                    you with any inquiries you may have.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="contact-img">
                <img src={contact} alt="contact" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-mail">
        <div className="container">
          <div className="contact-mail-data">
            <h3>ORIGINATORS</h3>
            <img className="underline" src={underline} alt="underline" />
          </div>
          <div className="FOUNDER">
            <div className="row founder-row g-4">
              <div className="col-4">
                <div className="card">
                  <img src={boss_1} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-center">Vishal Kalathiya</h5>
                    <p className="card-text text-center">CO ORIGINATOR</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="card card_2">
                  <img src={boss_2} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      Abhishek Kalathiya
                    </h5>
                    <p className="card-text text-center">CO ORIGINATOR</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-mail">
        <div className="container">
          <div className="contact-mail-data">
            <h3>WHAT ARE YOU THINKING? LET US KNOW!</h3>
            <img className="underline" src={underline} alt="underline" />
            <p>
              We would be glad to hear from you. Please fill in the form below
              or mail us your requirements at{" "}
              <a href="mailto:info@kalathiyainfotech.com">Info@kalathiyainfotech.com</a>
            </p>
          </div>
        </div>
      </section>

      <Touch />

      <Footer />
    </div>
  );
};

export default Contact;
