import "./Components/css/styles.css";
import Home from "./Components/pages/Home";
import About from "./Components/pages/About";
import Navbar from "./Components/pages/Navbar";
import { Route, Routes } from "react-router-dom";
import Web_development from "./Components/pages/Web_development";
import Testimonial from "./Components/pages/Testimonial";
import Career from "./Components/pages/Career";
import Contact from "./Components/pages/Contact";
import Responsive_web from "./Components/pages/Responsive_web";
import Uiux from "./Components/pages/Uiux";
import Mobileapp from "./Components/pages/Mobileapp";
import Seo from "./Components/pages/Seo";
import Digital from "./Components/pages/Digital";
import React_native from "./Components/pages/React_native";
import Full_Stack from "./Components/pages/Full_Stack";
import { useEffect, useState } from "react";
import Loader from "./Components/pages/Loader";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="App">
      {loading ? <Loader /> : <></>}
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/web_development" element={<Web_development />} />
        <Route path="/responsive_web" element={<Responsive_web />} />
        <Route path="/uiux" element={<Uiux />} />
        <Route path="/mobileapp" element={<Mobileapp />} />
        <Route path="/Seo" element={<Seo />} />
        <Route path="/digital" element={<Digital />} />
        <Route path="/react_native" element={<React_native />} />
        <Route path="/full_stack" element={<Full_Stack />} />
      </Routes>
    </div>
  );
}

export default App;
