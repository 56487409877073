import React, { useState } from "react";
import "../css/media.css";
import Buildimg from "../images/Build-img.png";
import underline from "../images/underline.png";
import underline_1 from "../images/underline-1-1.png";
import group36 from "../images/Group-36.png";
import group37 from "../images/Group-37.png";
import group38 from "../images/Group-38.png";
import php from "../images/PHP12.png";
import android from "../images/android1.png";
import ios from "../images/ios12.png";
import codeigniter from "../images/codeigniter.png";
import wordpress from "../images/wordpress.png";
import opencart_1 from "../images/opencart-1.png";
import Laravel from "../images/Laravel.png";
import flutter from "../images/flutter.png";
import vue_js from "../images/vue-js.png";
import nuxtjs_logo from "../images/nuxtjs_logo.png";
import HTML5 from "../images/HTML5.png";
import MySQL from "../images/MySQL.png";
import firebase from "../images/firebase.png";
import figma from "../images/figma.png";
import Adobe_ill from "../images/Adobe-Illustrator.png";
import Adobe_xd from "../images/Adobe-XD.png";
import download from "../images/download.png";
import Group_61 from "../images/Group-61.png";
import Group_62 from "../images/Group-62.png";
import Group_63 from "../images/Group-63.png";
import Group_64 from "../images/Group-64.png";
import Group_65 from "../images/Group-65.png";
import Group_66 from "../images/Group-66.png";
import Group_67 from "../images/Group-67.png";
import Group_68 from "../images/Group-68.png";
import Group_69 from "../images/Group-69.png";
import hire_dev from "../images/hire_dev.png";
import mobile_app_1 from "../images/mobile-app (1).jpg";
import web_development_2 from "../images/web-development(2).png";
import ui_ux from "../images/ui-ux.png";
import _5124558_1 from "../images/5124558-1.png";
import Vector from "../images/Vector.png";
import Design from "../images/Design.png";
import Build from "../images/Build.png";
import Deliver from "../images/Deliver.png";
import Line_3 from "../images/Line-3.png";
import how_we_work_1_e1651466110142 from "../images/how-we-work-1-e1651466110142-removebg-preview.png";
import Group from "../images/Group.jpg";
import Group_39_1 from "../images/Group-39-1.png";
import Group_40 from "../images/Group-40.png";
import Group_41 from "../images/Group-41.png";
import Group_42 from "../images/Group-42.png";
import Footer from "./Footer";
import ReCAPTCHA from "react-recaptcha";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import quote1 from "../images/Double-quote1.png";
import quote2 from "../images/Double-quote2.png";
import quote3 from "../images/Double-quote3.png";
import quote4 from "../images/Double-quote4.png";
import vector1 from "../images/Vector1.png";
import vector2 from "../images/Vector-2.png";
import vector3 from "../images/Vector3.png";
import vector4 from "../images/Vector4.png";
import rico from "../images/rico.jpg";
import alex from "../images/alex.jpg";
import vaneet from "../images/vaneet.jpg";
import nick from "../images/nick.jpg";
import roman from "../images/roman.jpg";
import cedric from "../images/cedric.jpg";
import tom from "../images/tom.jpeg";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faLocationDot,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Touch from "./Touch";
import { Link } from "react-router-dom";

const Home = () => {
  const [ShowAllCourse, setShowAllCourse] = useState(true);
  const [showBackendCourse, setShowBackendCourse] = useState(false);
  const [ShowMobileCourse, setShowMobileCourse] = useState(false);
  const [ShowFront_endCourse, setShowFront_endCourse] = useState(false);
  const [ShowDatabaseCourse, setShowDatabaseCourse] = useState(false);
  const [ShowUiuxCourse, setShowUiuxCourse] = useState(false);

  const handleRadioChange = (course) => {
    setShowAllCourse(course === "all");
    setShowBackendCourse(course === "backend");
    setShowMobileCourse(course === "mobile");
    setShowFront_endCourse(course === "front_end");
    setShowDatabaseCourse(course === "database");
    setShowUiuxCourse(course === "uiux");
  };

  return (
    <div className="Homepage">
      <section className="We-Build-all">
        <div className="container">
          <div className="We-Build">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 we-build_text px-5">
                  <div className="we-data">
                    <div className="top-data">
                      <p>
                        "Think Unthinkable"
                      </p>
                    </div>
                    <div className="top-data">
                      <h2 className="build-text">Empowers Businesses with </h2>
                      <h2 className="software">Cutting-Edge Solutions for Digital Success</h2>
                    </div>
                    <div className="bottom-data">
                      <p className="bottom-data-p">
                        At Kalathiya Infotech, we are passionate about helping
                        businesses thrive in the digital landscape. With our
                        comprehensive range of services, cutting-edge
                        technologies, and a team of skilled experts, we empower
                        our clients to achieve their goals and elevate their
                        online presence.
                      </p>
                      <Link to={"/contact"}>
                        <button className="btn btn-primary button-TOUCH mt-3">
                          GET IN TOUCH
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-6 we-build_img">
                  <img src={Buildimg} alt="Build-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-infotech">
        <div className="container">
          <div className="about-infotech-data">
            <h2>ABOUT KALATHIYA INFOTECH</h2>
            <img className="underline" src={underline} alt="underline" />
            <p>
              Kalathiya Infotech is a dynamic IT company dedicated to delivering
              cutting-edge solutions that empower businesses to thrive in the
              digital age. With a focus on innovation, reliability, and customer
              satisfaction, we have established ourselves as a trusted partner
              for businesses worldwide.
            </p>
          </div>
          <div className="row about-infotech-row mt-4">
            <div className="col-4 about-infotech-col-4">
              <div className="card">
                <div className="img">
                  <img src={group36} className="card-img-top" alt="" />
                </div>
                <div className="card-body">
                  <h5 className="card-title">PEOPLE</h5>
                  <p className="card-text">
                    We perpetuate a positive sparkling surrounding, that can
                    encourage growth and strenghtens working bond and growth.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4 about-infotech-col-4">
              <div className="card">
                <div className="img">
                  <img src={group37} className="card-img-top" alt="" />
                </div>
                <div className="card-body">
                  <h5 className="card-title">INTEGRITY</h5>
                  <p className="card-text">
                    We hold ourselves to the highest ethical standards,
                    consistently demonstrating honesty, transparency, and
                    accountability in all that we do.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4 about-infotech-col-4">
              <div className="card">
                <div className="img">
                  <img src={group38} className="card-img-top" alt="" />
                </div>
                <div className="card-body">
                  <h5 className="card-title">SUCCESS</h5>
                  <p className="card-text">
                    Success is not just measured by financial achievements, but
                    by the positive impact we make on our clients, employees,
                    and communities
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-skills">
        <div className="container">
          <div className="our-skills-data">
            <h2>OUR SKILLS</h2>
            <img className="underline" src={underline_1} alt="underline" />
            <p>
              At Kalathiya Infotech, our skills are as diverse as our team
              members. From technical expertise to creative problem-solving, our
              collective abilities drive our success and enable us to deliver
              exceptional results for our clients. skills, combined with our
              dedication to excellence and integrity, form the foundation of our
              company's success. We continuously invest in the development of
              our team members, ensuring that we remain at the forefront of our
              industry and continue to deliver value to our clients.
            </p>
          </div>
        </div>
      </section>
      <section className="technologies-work-with">
        <div className="container">
          <div className="technologies-we-data">
            <h2>TECHNOLOGIES WE WORK WITH</h2>
            <img className="underline" src={underline} alt="underline" />
            <p className="btn-all-group">
              <div
                className="button-group"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <div className="top">
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio1"
                    autoComplete="off"
                    defaultChecked
                    onChange={() => handleRadioChange("all")}
                  />
                  <label
                    className="btn btn-outline-dark type all"
                    htmlFor="btnradio1"
                  >
                    ALL
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio2"
                    autoComplete="off"
                    onChange={() => handleRadioChange("backend")}
                  />
                  <label
                    className="btn btn-outline-dark type backend"
                    htmlFor="btnradio2"
                  >
                    BACKEND
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio3"
                    autoComplete="off"
                    onChange={() => handleRadioChange("mobile")}

                  />
                  <label
                    className="btn btn-outline-dark type mobile"
                    htmlFor="btnradio3"
                  >
                    MOBILE
                  </label>
                </div>
                <div className="buttom">
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio4"
                    autoComplete="off"
                    onChange={() => handleRadioChange("front_end")}
                  />
                  <label
                    className="btn btn-outline-dark type front-end"
                    htmlFor="btnradio4"
                  >
                    FRONT END
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio5"
                    autoComplete="off"
                    onChange={() => handleRadioChange("database")}
                  />
                  <label
                    className="btn btn-outline-dark type database"
                    htmlFor="btnradio5"
                  >
                    DATABASE
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio6"
                    autoComplete="off"
                    onChange={() => handleRadioChange("uiux")}
                  />
                  <label
                    className="btn btn-outline-dark type ui/ux"
                    htmlFor="btnradio6"
                  >
                    UI/UX DESIGN
                  </label>
                </div>
              </div>
            </p>
          </div>

          {showBackendCourse && (
            <div className="backend-course">
              <div className="technologies-we-img text-center">
                <div className="row mt-4 about-infotech-row">
                  <div className="col-3">
                    <img className="php" src={php} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img
                      className="codeigniter"
                      src={codeigniter}
                      alt="codeigniter"
                    />
                  </div>
                  <div className="col-3">
                    <img className="wordpress" src={wordpress} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="opencart_1" src={opencart_1} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Laravel" src={Laravel} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_61" src={Group_61} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_62" src={Group_62} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_63" src={Group_63} alt="PHP" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {ShowMobileCourse && (
            <div className="backend-course">
              <div className="technologies-we-img text-center">
                <div className="row mt-4 about-infotech-row">
                  <div className="col-3">
                    <img className="android" src={android} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="flutter" src={flutter} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="ios" src={ios} alt="PHP" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {ShowFront_endCourse && (
            <div className="backend-course">
              <div className="technologies-we-img text-center">
                <div className="row mt-4 about-infotech-row">
                  <div className="col-3">
                    <img className="vue_js" src={vue_js} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="nuxtjs_logo" src={nuxtjs_logo} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="HTML5" src={HTML5} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_64" src={Group_64} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_65" src={Group_65} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_66" src={Group_66} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_67" src={Group_67} alt="PHP" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {ShowDatabaseCourse && (
            <div className="backend-course">
              <div className="technologies-we-img text-center">
                <div className="row mt-4 about-infotech-row">
                  <div className="col-3">
                    <img className="MySQL" src={MySQL} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="firebase" src={firebase} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_68" src={Group_68} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_69" src={Group_69} alt="PHP" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {ShowUiuxCourse && (
            <div className="backend-course">
              <div className="technologies-we-img text-center">
                <div className="row mt-4 about-infotech-row">
                  <div className="col-3">
                    <img className="figma" src={figma} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Adobe_ill" src={Adobe_ill} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Adobe_xd" src={Adobe_xd} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="download" src={download} alt="PHP" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {ShowAllCourse && (
            <div className="backend-course">
              <div className="technologies-we-img text-center">
                <div className="row mt-4 about-infotech-row">
                  <div className="col-3">
                    <img className="php" src={php} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img
                      className="codeigniter"
                      src={codeigniter}
                      alt="codeigniter"
                    />
                  </div>
                  <div className="col-3">
                    <img className="wordpress" src={wordpress} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="opencart_1" src={opencart_1} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Laravel" src={Laravel} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="flutter" src={flutter} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="vue_js" src={vue_js} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="nuxtjs_logo" src={nuxtjs_logo} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="HTML5" src={HTML5} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="MySQL" src={MySQL} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="firebase" src={firebase} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="figma" src={figma} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="android" src={android} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="ios" src={ios} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Adobe_ill" src={Adobe_ill} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Adobe_xd" src={Adobe_xd} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="download" src={download} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_61" src={Group_61} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_62" src={Group_62} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_63" src={Group_63} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_64" src={Group_64} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_65" src={Group_65} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_66" src={Group_66} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_67" src={Group_67} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_68" src={Group_68} alt="PHP" />
                  </div>
                  <div className="col-3">
                    <img className="Group_69" src={Group_69} alt="PHP" />
                  </div>
                </div>
              </div>
            </div>
          )}


        </div>
      </section>
      <section className="our-core-services">
        <div className="container">
          <div className="our-core-data">
            <div className="our-core-data-top">
              <h2>OUR CORE SERVICES</h2>
              <img className="underline" src={underline} alt="underline" />
            </div>
            <div className="our-core-data-bottom">
              <div className="our-core-colum">
                <div className="row">
                  <div className="colum mobile_app_1_all">
                    <div className="card mobile_app_1 h-100">
                      <img
                        src={mobile_app_1}
                        className="card-img-top mobile_app_1-img"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">Mobile App Development</h5>
                        <p className="card-text">
                          We likely to have a team of skilled and experienced
                          Android app developers. Their expertise in the field,
                          combined with their years of experience, can
                          contribute to the successful execution of your app
                          project.
                        </p>
                      </div>
                      <div className="card-footer">
                        <Link className="card-button stretched-link" to="/mobileapp">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="colum web_development_2_all">
                    <div className="card web_development_2 h-100">
                      <img
                        src={web_development_2}
                        className="card-img-top web_development_2_img"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">Web Development</h5>
                        <p className="card-text">
                          Our web developer can make client base websites and
                          web apllication solutions. We Drop-ship Websites that
                          can help you for the growth of your business using
                          internet technology.
                        </p>
                      </div>
                      <div className="card-footer">
                        <Link className="card-button stretched-link" to="/web_development">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="colum ui_ux_all">
                    <div className="card ui_ux h-100">
                      <img
                        src={ui_ux}
                        className="card-img-top ui_ux-img"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">UI/UX Design</h5>
                        <p className="card-text">
                          UI design focuses on the visual elements and
                          interactive components of a product, including layout,
                          colors, typography, icons, buttons, and other
                          graphical elements
                        </p>
                      </div>
                      <div className="card-footer">
                        <a className="card-button" href="/uiux">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="colum hire_dev_all">
                    <div className="card hire_dev h-100">
                      <img
                        src={hire_dev}
                        className="card-img-top hire_dev-img"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">Hire Dedicated Developer</h5>
                        <p className="card-text">
                          We try to create team of developers for software
                          development project on a short-term, long-term, or
                          permanent basis with guaranteed project delivery at
                          affordable prices.
                        </p>
                      </div>
                      <div className="card-footer">
                        <Link className="card-button stretched-link" to="/">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="colum _5124558_1_all">
                    <div className="card _5124558_1 h-100">
                      <img
                        src={_5124558_1}
                        className="card-img-top _5124558_1_img"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">24/7 Support</h5>
                        <p className="card-text">
                          We provide the best services, ensuring immediate
                          support interruption for any of your technical issues
                          or challenges, our team is always available to assist
                          you.
                        </p>
                      </div>
                      <div className="card-footer">
                        <Link className="card-button stretched-link " to="/contact">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="we-software-development">
        <div className="container">
          <div className="we-software-data">
            <div className="we-software-top">
              <h2>WE SIMPLIFY THE SOFTWARE DEVELOPMENT PROCESS</h2>
              <img className="underline" src={underline} alt="underline" />
            </div>
            <div className="we-software-bottom">
              <div className="we-software-colum">
                <div className="row">
                  <div className="col-3">
                    <div className="row we-software-row-img">
                      <div className="col-3">
                        <img className="Vector" src={Vector} alt="Vector" />
                      </div>
                      <div className="col-9">
                        <img className="Line_3" src={Line_3} alt="Line_3" />
                      </div>
                    </div>
                    <div className="we-software-text">
                      <h3 className="text-title">Discover</h3>
                      <p className="text-p">
                        We shape brands through exploration, applying in-depth
                        research to challenge assumptions at every turn.
                      </p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="row we-software-row-img">
                      <div className="col-3">
                        <img className="Design" src={Design} alt="Design" />
                      </div>
                      <div className="col-9">
                        <img className="Line_3" src={Line_3} alt="Line_3" />
                      </div>
                    </div>
                    <div className="we-software-text">
                      <h3 className="text-title">Design</h3>
                      <p className="text-p">
                        We create brands through exploration, applying in-depth
                        research to challenge assumptions at every turn.
                      </p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="row we-software-row-img">
                      <div className="col-3">
                        <img className="Build" src={Build} alt="Build" />
                      </div>
                      <div className="col-9">
                        <img className="Line_3" src={Line_3} alt="Line_3" />
                      </div>
                    </div>
                    <div className="we-software-text">
                      <h3 className="text-title">Build</h3>
                      <p className="text-p">
                        We strive the increment by using lastest technology,
                        creating work easy by doing smart work in efficient time
                        period.
                      </p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="row we-software-row-img">
                      <div className="col-3">
                        <img className="Deliver" src={Deliver} alt="Deliver" />
                      </div>
                      <div className="col-9"></div>
                    </div>
                    <div className="we-software-text">
                      <h3 className="text-title">Deliver</h3>
                      <p className="text-p">
                        By embracing iteration, we foster innovation,
                        adaptability, and efficiency in everything we do.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="how-we-work">
        <div className="how-work-data">
          <div className="container">
            <div className="how-work-data-top">
              <h2>HOW WE WORK</h2>
              <img className="underline" src={underline} alt="underline" />
            </div>
          </div>
          <div className="bg">
            <div className="container">
              <div className="how-work-data-bottom">
                <div className="how-work-colum">
                  <div className="row">
                    <div className="col-4 how-work-4-img">
                      <img
                        className="how_we_work_1_e1651466110142-img"
                        src={how_we_work_1_e1651466110142}
                        alt="how_we_work_1_e1651466110142"
                      />
                    </div>
                    <div className="col-8 how-work-8-data">
                      <ul>
                        <li>
                          We have a pre-defined way of working determined
                          through various experiments to be the most effective
                          for us, our clients, and the product we are
                          developing.
                        </li>
                        <li>
                          We use an Agile method for all types of development,
                          whether website design or mobile app development.
                        </li>
                        <li>
                          Every day, we inform our clients about our progress.
                          We get a lot of work done on some days and only a
                          little on others. However, transparency is our motto.
                        </li>
                        <li>
                          We have expertise in all the technologies we have
                          worked on, but we are always looking for ways to grow
                          and expand our knowledge.
                        </li>
                        <li>
                          We know that one bug causes another 20, so we’ve
                          covered our products with automated test cases. We
                          also understand that one thing can cause another to
                          break. So we use GitHub or BitBucket to keep track of
                          all the changes.
                        </li>
                        <li>
                          To understand requirements, manage tasks, and
                          prioritize tasks, we use JIRA, Trello, Asana, and
                          PivotTracker.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="join-our-family">
        <div className="container">
          <div className="join-family-data">
            <div className="join-family-top">
              <h2>LET'S JOIN OUR FAMILY</h2>
              <img className="underline" src={underline} alt="underline" />
            </div>
            <div className="join-family-bottom">
              <div className="join-family-colum">
                <div className="row">
                  <div className="col-6">
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            React.js & Node.js
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <h5 className="accordion-title">
                              Required skills:
                            </h5>
                            <ul>
                              <li>
                                React.js & Node.js both technologies knowledge
                                required.
                              </li>
                              <li>Min. 1 year of experience</li>
                              <li>Communication Skills must.</li>
                              <li>Min. Bachelor Degree (Computer Field)</li>
                            </ul>
                            <h5 className="accordion-title">Salary :</h5>
                            <ul>
                              <li>As per industry standards</li>
                            </ul>
                            <h5 className="accordion-title">Working Hours:</h5>
                            <ul>
                              <li>9:00 a.m. to 6:30 p.m. (Monday–Friday)</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            Laravel & Vue.JS
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <h5 className="accordion-title">
                              Required skills:
                            </h5>
                            <ul>
                              <li>
                                React.js & Node.js both technologies knowledge
                                required.
                              </li>
                              <li>Min. 1 year of experience</li>
                              <li>Communication Skills must.</li>
                              <li>Min. Bachelor Degree (Computer Field)</li>
                            </ul>
                            <h5 className="accordion-title">Salary :</h5>
                            <ul>
                              <li>As per industry standards</li>
                            </ul>
                            <h5 className="accordion-title">Working Hours:</h5>
                            <ul>
                              <li>9:00 a.m. to 6:30 p.m. (Monday–Friday)</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            Codeigniter & Laravel
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <h5 className="accordion-title">
                              Required skills:
                            </h5>
                            <ul>
                              <li>
                                React.js & Node.js both technologies knowledge
                                required.
                              </li>
                              <li>Min. 1 year of experience</li>
                              <li>Communication Skills must.</li>
                              <li>Min. Bachelor Degree (Computer Field)</li>
                            </ul>
                            <h5 className="accordion-title">Salary :</h5>
                            <ul>
                              <li>As per industry standards</li>
                            </ul>
                            <h5 className="accordion-title">Working Hours:</h5>
                            <ul>
                              <li>9:00 a.m. to 6:30 p.m. (Monday–Friday)</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                            aria-expanded="false"
                            aria-controls="flush-collapseFour"
                          >
                            PHP Team Leader
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <h5 className="accordion-title">
                              Required skills:
                            </h5>
                            <ul>
                              <li>
                                React.js & Node.js both technologies knowledge
                                required.
                              </li>
                              <li>Min. 1 year of experience</li>
                              <li>Communication Skills must.</li>
                              <li>Min. Bachelor Degree (Computer Field)</li>
                            </ul>
                            <h5 className="accordion-title">Salary :</h5>
                            <ul>
                              <li>As per industry standards</li>
                            </ul>
                            <h5 className="accordion-title">Working Hours:</h5>
                            <ul>
                              <li>9:00 a.m. to 6:30 p.m. (Monday–Friday)</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFive"
                            aria-expanded="false"
                            aria-controls="flush-collapseFive"
                          >
                            Flutter Developer
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <h5 className="accordion-title">
                              Required skills:
                            </h5>
                            <ul>
                              <li>
                                React.js & Node.js both technologies knowledge
                                required.
                              </li>
                              <li>Min. 1 year of experience</li>
                              <li>Communication Skills must.</li>
                              <li>Min. Bachelor Degree (Computer Field)</li>
                            </ul>
                            <h5 className="accordion-title">Salary :</h5>
                            <ul>
                              <li>As per industry standards</li>
                            </ul>
                            <h5 className="accordion-title">Working Hours:</h5>
                            <ul>
                              <li>9:00 a.m. to 6:30 p.m. (Monday–Friday)</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 join-6-img">
                    <img src={Group} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="happy-client">
        <div className="container">
          <div className="row">
            <div className="col-3">
              <div className="card mb-3" style={{ maxWidth: 540 }}>
                <div className="row g-0">
                  <div className="col-md-3">
                    <img
                      src={Group_39_1}
                      className="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <h5 className="card-title">400+</h5>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-body">
                      <h5 className="card-title">HAPPY CLIENT</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 PROJECT">
              <div className="card mb-3" style={{ maxWidth: 540 }}>
                <div className="row g-0">
                  <div className="col-md-3">
                    <img
                      src={Group_40}
                      className="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <h5 className="card-title">600+</h5>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-body">
                      <h5 className="card-title">PROJECT COMPLETED</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 EXPERIENCE">
              <div className="card mb-3" style={{ maxWidth: 540 }}>
                <div className="row g-0">
                  <div className="col-md-3">
                    <img
                      src={Group_41}
                      className="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <h5 className="card-title">7+</h5>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-body">
                      <h5 className="card-title">EXPERIENCE</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 DEVELOPERS">
              <div className="card mb-3" style={{ maxWidth: 540 }}>
                <div className="row g-0">
                  <div className="col-md-3">
                    <img
                      src={Group_42}
                      className="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <h5 className="card-title">30+</h5>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card-body">
                      <h5 className="card-title">DEVELOPERS</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
