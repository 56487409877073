import React from "react";
import "../css/media.css";
import ezgif_111 from "../images/ezgif-111.png";
import underline from "../images/underline.png";
import _1 from "../images/1.png";
import _2 from "../images/2.png";
import _3 from "../images/3.png";
import _4 from "../images/4.png";
import _5 from "../images/5.png";
import _6 from "../images/6.png";
import _7 from "../images/7.png";
import _8 from "../images/8.png";
import Group_43_2 from "../images/Group-43-2.png";
import Group_44_2 from "../images/Group-44-2.png";
import Group_45_1 from "../images/Group-45-1.png";
import Group_47_1 from "../images/Group-47-1.png";
import Group_46_1 from "../images/Group-46-1.png";
import Group_48_1 from "../images/Group-48-1.png";
import Group_49_1 from "../images/Group-49-1.png";
import Group_50_1 from "../images/Group-50-1.png";
import Group_51_1 from "../images/Group-51-1.png";
import Group_52_1 from "../images/Group-52-1.png";
import Group_53_1 from "../images/Group-53-1.png";
import Group_54_1 from "../images/Group-54-1.png";
import mobile_design3 from "../images/mobile_design3.png";
import Touch from "./Touch";
import Footer from "./Footer";

const Responsive_web = () => {
  return (
    <div>
      <section className="We-Build-all">
        <div className="container">
          <div className="We-Build">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 we-build_text px-5">
                  <div className="we-data">
                    <div className="top-data">
                      <h2 className="software">
                        RESPONSIVE WEB DESIGN DEVELOPMENT
                      </h2>
                    </div>
                    <div className="bottom-data">
                      <p>
                        In today's digital landscape, it's essential to prioritize
                        the needs of individuals who have been immersed in the
                        online world since childhood, seamlessly transitioning
                        between web and mobile experiences. Recognizing this, we
                        emphasize responsive web design to cater to this diverse
                        audience. Our team of design specialists is dedicated to
                        tailoring standards to suit any platform or device,
                        ensuring your website or mobile app resonates with a
                        wide-ranging audience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 we-build_img responsive-web-imgaot">
                  <img
                    className="responsive-web-img"
                    src={ezgif_111}
                    alt="ezgif_111"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="next-gen">
        <div className="container">
          <div className="next-gen-data-head">
            <div className="next-gen-data-top-head">
              <h2>WEB APPLICATION DEVELOPMENT SERVICES</h2>
              <img className="underline" src={underline} alt="underline" />
              <p>
                <span>Innovative</span> Responsive Web Design Alongside Unrivaled {" "}
                <span>Expertise</span>
              </p>
            </div>
          </div>
        </div>
        <div className="next-gen-data-bottom">
          <div className="container">
            <div className="row next-gen-all-row">
              <div className="col-3 next-gen-all-col-3">
                <div className="responsive-gen-card-border">
                  <div className="next-gen-card-data">
                    <div className="row next-gen-card-row">
                      <div className="col-4 next-gen-card-col-4">
                        <div className="next-gen-card-img">
                          <img src={_1} alt="_1" />
                        </div>
                      </div>
                      <div className="col-8 next-gen-card-col-8">
                        <div className="next-gen-card-h3">
                          <h3>Responsive Web Design Services</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 next-gen-all-col-3">
                <div className="interface-gen-card-border">
                  <div className="next-gen-card-data">
                    <div className="row next-gen-card-row">
                      <div className="col-4 next-gen-card-col-4">
                        <div className="next-gen-card-img">
                          <img src={_2} alt="_2" />
                        </div>
                      </div>
                      <div className="col-8 next-gen-card-col-8">
                        <div className="next-gen-card-h3">
                          <h3>Interface Design Services</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 next-gen-all-col-3">
                <div className="feature-gen-card-border">
                  <div className="next-gen-card-data">
                    <div className="row next-gen-card-row">
                      <div className="col-4 next-gen-card-col-4">
                        <div className="next-gen-card-img">
                          <img src={_3} alt="_3" />
                        </div>
                      </div>
                      <div className="col-8 next-gen-card-col-8">
                        <div className="next-gen-card-h3">
                          <h3>Feature-Rich And Advanced Web Design</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 next-gen-all-col-3">
                <div className="highly-gen-card-border">
                  <div className="next-gen-card-data">
                    <div className="row next-gen-card-row">
                      <div className="col-4 next-gen-card-col-4">
                        <div className="next-gen-card-img">
                          <img src={_4} alt="_4" />
                        </div>
                      </div>
                      <div className="col-8 next-gen-card-col-8">
                        <div className="next-gen-card-h3">
                          <h3>Highly Impressive and Professional Design</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 next-gen-all-col-3">
                <div className="interface-gen-card-border">
                  <div className="next-gen-card-data">
                    <div className="row next-gen-card-row">
                      <div className="col-4 next-gen-card-col-4">
                        <div className="next-gen-card-img">
                          <img src={_5} alt="_5" />
                        </div>
                      </div>
                      <div className="col-8 next-gen-card-col-8">
                        <div className="next-gen-card-h3">
                          <h3>Landing Page Redesign & Optimization</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 next-gen-all-col-3">
                <div className="user-gen-card-border">
                  <div className="next-gen-card-data">
                    <div className="row next-gen-card-row">
                      <div className="col-4 next-gen-card-col-4">
                        <div className="next-gen-card-img">
                          <img src={_6} alt="_6" />
                        </div>
                      </div>
                      <div className="col-8 next-gen-card-col-8">
                        <div className="next-gen-card-h3">
                          <h3>User Interface & Front-end Design</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 next-gen-all-col-3">
                <div className="responsive-gen-card-border">
                  <div className="next-gen-card-data">
                    <div className="row next-gen-card-row">
                      <div className="col-4 next-gen-card-col-4">
                        <div className="next-gen-card-img">
                          <img src={_7} alt="_7" />
                        </div>
                      </div>
                      <div className="col-8 next-gen-card-col-8">
                        <div className="next-gen-card-h3">
                          <h3>Liquid Web Design</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 next-gen-all-col-3">
                <div className="adaptive-gen-card-border">
                  <div className="next-gen-card-data">
                    <div className="row next-gen-card-row">
                      <div className="col-4 next-gen-card-col-4">
                        <div className="next-gen-card-img">
                          <img src={_8} alt="_8" />
                        </div>
                      </div>
                      <div className="col-8 next-gen-card-col-8">
                        <div className="next-gen-card-h3">
                          <h3>Adaptive Web Design</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-expertise">
        <div className="container">
          <div className="our-expertise-data-head">
            <div className="our-expertise-data-top-head">
              <h2>OUR CORE SERVICES</h2>
              <img className="underline" src={underline} alt="underline" />
            </div>
            <div className="our-core-data-bottom">
              <div className="row our-expertise-data-row">
                <div className="col-2 our-expertise-data-col-2">
                  <div className="education-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_43_2} alt="Group_43_2" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Education</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="health-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_44_2} alt="Group_44_2" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Health Care</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="commerce-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_45_1} alt="Group_45_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>E-commerce</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="fitness-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_47_1} alt="Group_47_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Fitness</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="lifestyle-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_46_1} alt="Group_46_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Lifestyle</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="transportation-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_48_1} alt="Group_48_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Transportation</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="fitness-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_49_1} alt="Group_49_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Real Estate</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="event-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_50_1} alt="Group_50_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Event Management</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="lifestyle-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_51_1} alt="Group_51_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Travel</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="education-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_52_1} alt="Group_52_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Finance</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="health-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_53_1} alt="Group_53_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Social Network</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="commerce-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_54_1} alt="Group_54_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Food & Drink</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-experience">
        <div className="container">
          <div className="our-experience-data-top-head">
            <h2>OUR EXPERIENCE</h2>
            <img className="underline" src={underline} alt="underline" />
          </div>
          <div className="row">
            <div className="col-6">
              <div className="experience-img">
                <img src={mobile_design3} alt="mobile_design3" />
              </div>
            </div>
            <div className="col-6">
              <div className="experience-first">
                <div className="experience-heading">
                  <h2>
                    Create Impact With Every Touch Point Of Users On Any Device
                  </h2>
                </div>
                <div className="experience-data">
                  <p>
                    Make every interaction count across all user touchpoints,
                    regardless of the device they use. With the shift towards
                    mobile, ensuring websites are responsive has become
                    paramount. At Kalathiya Infotech, our expertise in web
                    development spans the transition from desktop to mobile to
                    Progressive Web Apps (PWA). This journey equips us with
                    unparalleled mastery in crafting responsive and
                    mobile-friendly designs, ensuring seamless experiences for
                    users on any device.
                  </p>
                </div>
                <div className="experience-data2">
                  <p>
                    Kalathiya Infotech is one of the leading web design companies
                    with expertise and experience across all facets of web user
                    experience and many design trends and protocols that have
                    evolved over the years. We are one of the early adopters of
                    responsive web design and have already designed and built
                    countless successful websites with responsive design
                    elements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="get-a-free-mobile">
        <div className="get-a-free-mobile-all">
          <div className="container">
            <div className="row get-a-free-row">
              <div className="col-9 get-a-free-col-9">
                <div className="get-a-free-data">
                  <h3>Are you looking for a better design than you Visulaization?</h3>
                  <h2>your prediction?</h2>
                </div>
              </div>
              <div className="col-3 get-a-free-col-3">
                <div className="get-a-free-button">
                  <a href="/contact">
                    <button className="btn btn-warning">CONTACT US</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Touch />
      <Footer />
    </div>
  );
};

export default Responsive_web;
