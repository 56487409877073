import React from 'react'
import mobile from '../images/mobile.png'
import underline from '../images/underline.png'
import group55 from '../images/Group-55.png'
import group56 from '../images/Group-56.png'
import group57 from '../images/Group-57.png'
import group58 from '../images/Group-58.png'
import group59 from '../images/Group-59.png'
import group60 from '../images/Group-60.png'
import Website_Development from '../images/Website_Development.png'
import Footer from './Footer'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import quote1 from '../images/Double-quote1.png'
import quote2 from '../images/Double-quote2.png'
import quote3 from '../images/Double-quote3.png'
import quote4 from '../images/Double-quote4.png'
import vector1 from '../images/Vector1.png'
import vector2 from '../images/Vector-2.png'
import vector3 from '../images/Vector3.png'
import vector4 from '../images/Vector4.png'
import rico from '../images/rico.jpg'
import alex from '../images/alex.jpg'
import vaneet from '../images/vaneet.jpg'
import nick from '../images/nick.jpg'
import roman from '../images/roman.jpg'
import cedric from '../images/cedric.jpg'
import tom from '../images/tom.jpeg'
import '../css/media.css'
import Group_43_2 from '../images/Group-43-2.png'
import Group_44_2 from '../images/Group-44-2.png'
import Group_45_1 from '../images/Group-45-1.png'
import Group_46_1 from '../images/Group-46-1.png'
import Group_47_1 from '../images/Group-47-1.png'
import Group_48_1 from '../images/Group-48-1.png'
import Group_49_1 from '../images/Group-49-1.png'
import Group_50_1 from '../images/Group-50-1.png'
import Group_51_1 from '../images/Group-51-1.png'
import Group_52_1 from '../images/Group-52-1.png'
import Group_53_1 from '../images/Group-53-1.png'
import Group_54_1 from '../images/Group-54-1.png'
import Touch from './Touch'

const Mobileapp = () => {
    return (
        <div>
            <section className='mobile'>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="mobile-img">
                                <img src={mobile} alt='mobile' />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mobile-first">
                                <div className="mobile-heading">
                                    <h2>MOBILE APPLICATION
                                        DEVELOPMENT</h2>
                                </div>
                                <div className="mobile-data">
                                    <p>We are leading mobile application development services with more than a decade of experience and expertise. Get remarkable mobile applications with an innovative approach for small to large scale enterprises.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="web-application-development-services">
                <div className="container">
                    <div className="web-application-services-data">
                        <div className="web-application-services-data-top">
                            <h2>MOBILE APP DEVELOPMENT SERVICES</h2>
                            <img className="underline" src={underline} alt="underline" />
                            <p>
                                The Kalathiya Infotech is a renowned website development service company catering to various industries with our bespoke website development services.
                            </p>
                        </div>
                    </div>

                    <div className="web-application-services-data-bottom">
                        <div className="row web-application-card-row">
                            <div className="col-4 web-application-card-col-4">
                                <div className="custom-application-border-card">
                                    <div className="row web-application-row-img">
                                        <div className="col-3 web-application-col-6-img">
                                            <img src={group55} alt="group55" />
                                        </div>
                                        <div className="col-9 web-application-col-6-data-h3">
                                            <h3>Android App Development</h3>
                                        </div>
                                    </div>
                                    <p className="web-application-col-6-p">
                                        Our Android App Developers aim to develop the best Android applications with robust code, user-friendly interface, and seamless UX.
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 web-application-card-col-4">
                                <div className="portal-application-border-card">
                                    <div className="row web-application-row-img">
                                        <div className="col-3 web-application-col-6-img">
                                            <img src={group56} alt="group56" />
                                        </div>
                                        <div className="col-9 web-application-col-6-data-h3">
                                            <h3>iPhone App Development</h3>
                                        </div>
                                    </div>
                                    <p className="web-application-col-6-p">
                                        Our iPhone  App development methodology functions with a mission to boost your business for future growth to sustain in the digital area.
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 web-application-card-col-4">
                                <div className="commerce-application-border-card">
                                    <div className="row web-application-row-img">
                                        <div className="col-3 web-application-col-6-img">
                                            <img src={group57} alt="group57" />
                                        </div>
                                        <div className="col-9 web-application-col-6-data-h3">
                                            <h3>Hybrid App Development</h3>
                                        </div>
                                    </div>
                                    <p className="web-application-col-6-p">
                                        We deliver hybrid apps that engage users, provide them with justified benefits and leave an impactful impression on their minds.
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 web-application-card-col-4">
                                <div className="integration-application-border-card">
                                    <div className="row web-application-row-img">
                                        <div className="col-3 web-application-col-6-img">
                                            <img src={group58} alt="group58" />
                                        </div>
                                        <div className="col-9 web-application-col-6-data-h3">
                                            <h3>Cross-platform Mobile App Development</h3>
                                        </div>
                                    </div>
                                    <p className="web-application-col-6-p">
                                        We use Cross-platform projects such as Appcelerator, Cordova (PhoneGap), and dozens of other tools to facilitate the mobile development process.
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 web-application-card-col-4">
                                <div className="mobile-application-border-card">
                                    <div className="row web-application-row-img">
                                        <div className="col-3 web-application-col-6-img">
                                            <img src={group59} alt="group59" />
                                        </div>
                                        <div className="col-9 web-application-col-6-data-h3">
                                            <h3>We arable App Development</h3>
                                        </div>
                                    </div>
                                    <p className="web-application-col-6-p">
                                        We build customized and custom-centric applications that run seamlessly on wearable devices like smartwatches and smart-band machines.
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 web-application-card-col-4">
                                <div className="flexible-application-border-card">
                                    <div className="row web-application-row-img">
                                        <div className="col-3 web-application-col-6-img">
                                            <img src={group60} alt="group60" />
                                        </div>
                                        <div className="col-9 web-application-col-6-data-h3">
                                            <h3>Enterprise Mobility Solutions</h3>
                                        </div>
                                    </div>
                                    <p className="web-application-col-6-p">
                                        We provide Enterprise Mobility Solutions that help your enterprise multiply. It controls and monitors data securely and authentically.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features-of-Website-development ">
                <div className="container">
                    <div className="row features-of-Website-row">
                        <div className="col-6 features-of-Website-col-6">
                            <div className="features-of-Website-data">
                                <div className="features-of-h3">
                                    <h3 className="features-top-h3">Features of</h3>
                                    <h3 className="features-bottom-h3">Mobile APP Development</h3>
                                </div>
                                <div className="features-of-p">
                                    <p>
                                        Mobile app development is a crucial software process in today’s world
                                        of digitalization and technology. Everyone uses mobile apps due to their
                                        user-friendliness and flexibility.
                                    </p>
                                </div>
                                <div className="features-of-ul">
                                    <ul>
                                        <li>Proper understanding of client’s requirement</li>
                                        <li>User – Friendly content</li>
                                        <li>Adequate structure of a website</li>
                                        <li>Friendly admin page for marketing purposes</li>
                                        <li>A checkout page</li>
                                        <li>Security</li>
                                        <li>Required plugins for backend purpose</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 features-image">
                            <div className="features-of-Website-img">
                                <img src={Website_Development} alt="Website_Development" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-a-free-mobile">
                <div className="get-a-free-mobile-all">
                    <div className="container">
                        <div className="row get-a-free-row">
                            <div className="col-9 get-a-free-col-9">
                                <div className="get-a-free-data">
                                    <h3>Get a Free Mobile App Development consultation</h3>
                                    <h2>with our experts today!</h2>
                                </div>
                            </div>
                            <div className="col-3 get-a-free-col-3">
                                <div className="get-a-free-button">
                                    <a href="/contact">
                                        <button className="btn btn-warning">CONTACT US</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="our-expertise">
                <div className="container">
                    <div className="our-expertise-data-head">
                        <div className="our-expertise-data-top-head">
                            <h2>OUR EXPERTISE</h2>
                            <img className="underline" src={underline} alt="underline" />
                        </div>
                        <div className="our-core-data-bottom">
                            <div className="row our-expertise-data-row">
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="education-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_43_2} alt="Group_43_2" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Education</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="health-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_44_2} alt="Group_44_2" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Health Care</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="commerce-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_45_1} alt="Group_45_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>E-commerce</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="fitness-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_47_1} alt="Group_47_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Fitness</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="lifestyle-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_46_1} alt="Group_46_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Lifestyle</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="transportation-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_48_1} alt="Group_48_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Transportation</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="fitness-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_49_1} alt="Group_49_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Real Estate</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="event-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_50_1} alt="Group_50_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Event Management</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="lifestyle-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_51_1} alt="Group_51_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Travel</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="education-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_52_1} alt="Group_52_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Finance</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="health-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_53_1} alt="Group_53_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Social Network</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="commerce-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_54_1} alt="Group_54_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Food & Drink</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Touch />

            <Footer />

        </div>
    )
}

export default Mobileapp
